.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: #24d924;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;  
}

.whatsapp-icon:hover, .tg-icon:hover { 
    background-color: #1a971a;
    animation-name: unset;
    opacity: 0.8;
}

.whatsapp-icon svg {
  width: 50px;
  height: 50px;
}

.tg-icon svg {
  width: 50px;
  height: 50px;
  stroke: #03a9f4;
}

.tg-icon {
    bottom: 150px;
    background: #03a9f4;
}

.tg-icon:hover {
    background: #03a9f4;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)scale(1);
  }
  50% {
    transform: rotate(22deg)scale(1.2);
  }
  100% {
    transform: rotate(0deg)scale(1);
  }
}